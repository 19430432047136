@tailwind base;
@tailwind components;
@tailwind utilities;

#root, html, body {
    height: 100%;
    background-color: #6d5d6e;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.custom-shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border: black;
    border-style: solid;
    border-width: 3px;
}

.info-shadow {
    box-shadow: 5px 5px 0px 0px rgba(0,0,0,1),
10px 10px 0px 0px rgba(0,0,0,0.6),
15px 15px 0px 0px rgba(0,0,0,0.4),
20px 20px 0px 0px rgba(0,0,0,0.2),
25px 25px 0px 0px rgba(0,0,0,0.1);
}

.neon {
    border: 1px solid white;
    color: black;
    padding: 0;
}